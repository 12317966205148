
import { defineComponent } from 'vue'
import Menu from '@/components/Menu.vue'
import { store } from '@/store'

export default defineComponent({
  name: 'App',
  components: {
    Menu
  },
  methods: {
    updateMobileState() {
      if (window.innerWidth < 730)
        store.commit('setIsMobile', true);
      else
        store.commit('setIsMobile', false);
    }
  },
  created() {
    this.updateMobileState();
    window.addEventListener('resize', this.updateMobileState);
  },
  unmounted() {
    window.removeEventListener('resize', this.updateMobileState);
  },
  watch: {
    $route(to) {
      if (process.env.NODE_ENV === 'production') {
        (window as any).gtag('event', 'page_view', {
          page_title: to.name,
          page_location: to.fullPath,
          page_path: to.path
        });
      }
    }
  }
});
