
import { defineComponent } from 'vue'
import { store } from '@/store'

export default defineComponent({
  name: 'Project',
  props: {
    project: {}
  },
  data() {
    const classes = ['red', 'blue', 'green', 'yellow', 'orange'];
    const index = Math.floor(Math.random() * Math.floor(classes.length - 1));
    return {
      colorClass: classes[index],
      imgHover: false
    }
  },
  methods: {
    cleanSearch() {
      store.commit('clearSearch');
    }
  },
  directives: {
    lazyload: {
      mounted(el: HTMLElement) {
        const loadImage = () => {
          const aTag = Array.from(el.children).find(
            elem => elem.nodeName === "A"
          ) as HTMLElement;
          const imageElement = aTag.children[0] as HTMLImageElement;

          if (imageElement) {
            imageElement.addEventListener("load", () => {
              el.classList.remove('squeleton-image');
            });
            imageElement.src = imageElement.dataset.url as string; 
          }
        }

        const handleIntersect = (entries: any, observer: any) => {
          entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
              loadImage();
              observer.unobserve(el);
            }
          });
        }

        const createObserver = () => {
          const options: IntersectionObserverInit = {
            root: null,
            threshold: 0
          };
          const observer = new IntersectionObserver(handleIntersect, options);
          observer.observe(el);
        }

        if (window["IntersectionObserver"]) {
          createObserver();
        } else {
          loadImage();
        }
      },
      updated(el: HTMLElement) {
        const aTag = Array.from(el.children).find(
          elem => elem.nodeName === "A"
        ) as HTMLElement;
        const imageElement = aTag.children[0] as HTMLImageElement;

        if (imageElement) {
          el.classList.remove('squeleton-image');
          imageElement.src = imageElement.dataset.url as string; 
        }
      }
    }
  }
});
