
import Project from '@/components/Project.vue'

import Projects from '@/assets/projects-data.json'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Landing',
  components: {
    Project
  },
  data() {
    return {
      professionalProjects: [Projects["star-citizen"], Projects["sparks-of-hope"]],
      hobbyProjects: [Projects["open-engine"], Projects["the-unsummoning"]]
    }
  }
});
