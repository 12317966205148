
import { defineComponent } from 'vue'
import { store } from '@/store'
import router from '@/router'

export default defineComponent({
  name: 'Menu',
  methods: {
    moveToProjectsAndToggleSearch() {
      const isProjects = router.currentRoute.value.path === '/projects';
      if (!isProjects) {
        router.push('/projects');
        store.commit('setShowSearchMenu', true);
      } else {
        store.commit('setShowSearchMenu', !store.state.showSearchMenu);
      }
    }
  },
  computed: {
    isMobile() {
      return store.state.isMobile;
    },
    isProjectsAndSearch() {
      const isProjects = router.currentRoute.value.path === '/projects';
      const isSearch = store.state.showSearchMenu;
      return isProjects && isSearch;
    }
  }
});
